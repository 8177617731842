
/* eslint-disable */
import { request } from '@/ts/api';
import { startTimer, stopTimer } from '@/ts/Timer';
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: {
    Popup
  },
  setup() {
    //#region Data
    const router = useRouter();

    const settingsJson: Ref<any> = ref({});
    const folderName = ref('');
    const imageFiles: Ref<Array<any>> = ref([]);
    const addErrorMessage = ref('');
    const showCreateFolderButton = ref(false);
    const loading = ref(false);
    const loadingText = ref('');
    const fileInput: Ref<any> = ref(null);
    const thumbnailContainer: Ref<any> = ref(null);
    const thumbnailContainerWidth = ref(0);
    const thumbnailList = ref([]);

    const showFullImage = ref(false);
    const fullImageLink = ref('');

    const showDeletePopup = ref(false);
    const popupPromise: Ref<any> = ref();
    const popupResolve: Ref<any> = ref();

    const thumbnailSize = computed(() => {
      let size = Math.ceil(thumbnailContainerWidth.value / 10);
      if (size < 50) size = 50;

      return {
        maxHeight: `${size}px`,
        minHeight: `${size}px`,
        maxWidth: `${size}px`,
        minWidth: `${size}px`,
      };
    })
    //#endregion

    //#region Lifecycle
    onMounted(() => {
      document.title = 'Slideshow Photos';
      const observer = new ResizeObserver(thumbnailContainerSizeChanged);
      observer.observe(thumbnailContainer.value);

      const settings = localStorage.getItem('settings');
      if (settings) {
        const settingsObject = JSON.parse(settings);
        folderName.value = settingsObject?.folder;
        settingsJson.value = settingsObject;

        getThumbnails();
      } else {
        router.push('/');
      }
    })
    //#endregion
    
    //#region Methods
    const imageChange = (e: any) => {
      imageFiles.value = Array.from(e.target.files);
    }
    const addClicked = async () => {
      addErrorMessage.value = '';
      showCreateFolderButton.value = false;

      if (!folderName.value) {
        addErrorMessage.value = 'Please enter your folder name';
        return;
      }

      if (imageFiles.value.length == 0) {
        addErrorMessage.value = 'Please select at least 1 image to upload';
        return;
      }
      
      const folderExists = (await request.get(`/CheckFolderName?folder=${folderName.value}`)).data;
      if (folderExists) {
        const formData = new FormData();
        formData.append('folder', folderName.value);
        imageFiles.value.forEach((f:any) => formData.append('image', f));
        
        loading.value = true;
        let loaded = 0;
        await request.post('/AddImage', formData, {
          onUploadProgress: (e) => {
            loadingText.value = `Uploading ${Math.ceil((e.progress || 0) * 100)}%`
          },
          onDownloadProgress: (e) => {
            loadingText.value = `${e.event.target.response.substring(loaded, e.loaded)}`;
            loaded = e.loaded;
          }
        })
        loadingText.value = 'Upload Completed!'

        setTimeout(() => {
          loading.value = false;
          fileInput.value.value = null;
          imageFiles.value = [];

          getThumbnails();
        }, 3000);
      } else {
        addErrorMessage.value = `Folder <b>${folderName.value}<b> does not exists in the server<br>Do you want to create <b>${folderName.value}<b> and upload images?`;
        showCreateFolderButton.value = true;
      }
    }
    const createFolderClicked = async () => {
      addErrorMessage.value = '';
      showCreateFolderButton.value = false;

      try {
        loading.value = true;
        loadingText.value = 'Creating Folder';
        await request.get(`/CreateFolder?folder=${folderName.value}`);
        addClicked();
      } catch {
        addErrorMessage.value = 'Error creating your folder';
      }
    }
    const thumbnailContainerSizeChanged = (e: Array<ResizeObserverEntry>) => {
      thumbnailContainerWidth.value = e[0].contentRect.width; 
    }

    const getThumbnails = async () => {
      if (!folderName.value) {
        thumbnailList.value = [];
        document.title = 'Slideshow Images';
      } else {
        const folderExists = (await request.get(`/CheckFolderName?folder=${folderName.value}`)).data;
        if (!folderExists) {
          thumbnailList.value = [];
          document.title = 'Slideshow Images';
        } else {
          thumbnailList.value = (await request.get(`/GetThumbnails?folder=${folderName.value}`)).data;
          document.title = `${folderName.value} - Slideshow Images`;
        }
      }
    }

    const thumbnailClicked = async (img: string) => {
      fullImageLink.value = img.replace(/THUMB@/gi, 'COMP@');
      showFullImage.value = true;
    }
    const deleteImageClicked = (img: string) => {
      showDeletePopup.value = true;
    }

    const deletePopupButtonClicked = async (text: string) => {
      showDeletePopup.value = false;
      switch (text) {
        case 'Delete':
          try {
            request.delete(`/DeleteImage?folder=${settingsJson.value.folder}&filename=${fullImageLink.value.split('COMP@')[1]}`);
            showFullImage.value = false;
            thumbnailList.value = [];
            getThumbnails();
          } catch {
            console.log('error deleting image');
          }
          break;
      }
    }
    //#endregion
    
    //#region Watcher
    watch(folderName, (val) => {
      try {
        stopTimer();
      } catch (e) {
        console.log(e);
      }
      startTimer(getThumbnails, 1000);
    })
    //#endregion

    return {
      //#region Data
      settingsJson,
      folderName,
      addErrorMessage,
      showCreateFolderButton,
      loading,
      loadingText,
      fileInput,
      thumbnailContainer,
      thumbnailSize,
      thumbnailList,

      showFullImage,
      fullImageLink,

      showDeletePopup,
      //#endregion
      
      //#region Methods
      imageChange,
      addClicked,
      createFolderClicked,

      thumbnailClicked,
      deleteImageClicked,

      deletePopupButtonClicked,
      //#endregion
    }
  },
})
